import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../../components/shared-layout";
import useStores from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { CircularProgress, MenuItem, NativeSelect, Select, Tab, Tabs } from '@material-ui/core';
import CheckCircleOurlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TabPanel from '../../components/tab-panel';
import { useStyles } from '../../constants/style';
import CustomisedModal from '../../components/customised-modal';
import { toJS } from 'mobx';
import StudentTokenTab from '../../components/maintenance/student-token';
import { useTranslation } from 'react-i18next';
import VMCard from '../../components/collapsed-card';
import { getPermissionValidity, PermissionCategory } from '../../constants/options';

function TabsProps(index: number, activeTab: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${activeTab ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const PrepareStatusFilter = observer(({ callback }) => {
  const { filterStore } = useStores();
  const { t } = useTranslation();
  const [year, setYear] = useState<string>(" ");
  const [semester, setSemester] = useState(0);
  const DEFAULT_YEAR: string = new Date().getFullYear();

  useEffect(() => {
    filterStore.getSyncDataSemesterDateList().then(() => {
      setYear(DEFAULT_YEAR);
      if (filterStore.syncDataAcademicYearList.length > 0) {
        for (var ay of filterStore.syncDataAcademicYearList) {
          if (ay.yearDescription == DEFAULT_YEAR) {
            setYear(ay.yearDescription);
            break;
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    filterStore.getSemesterBasedOnYear(year);
    setSemester(0);
  }, [year]);

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as string);
  }

  const onChangeSemster = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSemester(event.target.value as number);
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-1">
          <div className="text-barPrimary mb-2">
            {t('YEAR')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            value={year}
            onChange={onChangeYear}
          >
            <MenuItem value=" ">{t('NOT_SELECTED')}</MenuItem>
            {
              filterStore.syncDataAcademicYearList &&
              filterStore.syncDataAcademicYearList.map((ay, index) => (
                <MenuItem key={`academic_year_list_${ay.id}_${index}`} value={ay.yearDescription}>
                  {ay.yearDescription}
                </MenuItem>
              ))
            }
          </Select>
        </div>

        <div className="col-span-1">
          <div className="text-barPrimary mb-2">
            {t('SEMESTER')}
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="bg-background w-full px-2"
            value={semester}
            onChange={onChangeSemster}
            disabled={year == " "}
          >
            <MenuItem value={0}>{t('NOT_SELECTED')}</MenuItem>
            {
              filterStore.semesterList.length > 0 &&
              filterStore.semesterList.map((sem, index) => (
                <MenuItem key={`semester_list_${sem.id}_${index}`} value={sem.id}>
                  {sem.semesterDescription.substring(8, 10)}
                </MenuItem>
              ))
            }
          </Select>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button
          className="bg-buttonPrimary text-white mr-4"
          onClick={() => {
            callback(year, 0)
          }}
        >
          {t('PREPARE_DATA_ALL_SEMESTERS')}
        </Button>
        <Button
          className="bg-buttonPrimary text-white"
          disabled={semester == 0}
          onClick={() => {
            callback(year, semester)
          }}
        >
          {t('PREPARE_DATA')}
        </Button>
      </div>
    </>
  );
});

const MaintenancePage = observer(() => {
  const headingStyle = "text-barPrimary px-4 text-lg 2xl:text-2xl font-bold uppercase";
  const { t } = useTranslation();
  const { rootStore, attendanceStore, maintenanceStore, filterStore } = useStores();
  const [tabValue, setTabValue] = useState<number>(0);
  const [showSyncDataModal, setShowSyncDataModal] = useState<boolean>(false);
  const [multiSync, setMultiSync] = useState<boolean>(false);
  const [tableID, setTableID] = useState<number>(0);

  useEffect(() => {
    maintenanceStore.getSyncDataStatus();
    maintenanceStore.getSchoolAttendancePrepareStatusList();
    maintenanceStore.getSyncStudentOverallScoreAndAttendanceRateStatus();
    maintenanceStore.getSyncLearningQueryResultStatus();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showFilter={false}
          showYearSemTerm={false}
          showClassCode={false}
          showDomain={false}
          showStaffList={false}
          showNewsArea={true}
          showSeachStudent={true}
        >
          <title>{t('MAINTENANCE')}</title>
          <div className="col-span-4 flex flex-col mx-2 2xl:mx-4" style={{ minHeight: '100vh' }}>

            <div className='border border-b-0 rounded-t-lg text-barPrimary p-4 mt-2 text-lg 2xl:text-2xl font-bold uppercase'>
              {t('MAINTENANCE')}
            </div>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={tabValue}
              onChange={handleTabChange}
              className="shadow-lg rounded-b-lg border mb-4"
            >
              {getPermissionValidity(PermissionCategory.SYSTEM, "Maintenance", "SyncData")
                && <Tab label={t('SYNC_DATA')} {...TabsProps(0, 0 === tabValue)} />}
              {getPermissionValidity(PermissionCategory.SYSTEM, "Maintenance", "PrepareAttendanceData")
                && <Tab label={t('SCHOOL_ATTENDANCE_DATA')} {...TabsProps(1, 1 === tabValue)} />}
              {getPermissionValidity(PermissionCategory.SYSTEM, "Maintenance", "PrepareAttendanceData")
                && <Tab label={t('STUDENT_PERFORMANCE_DATA')} {...TabsProps(2, 2 === tabValue)} />}
              {getPermissionValidity(PermissionCategory.SYSTEM, "Maintenance", "PrepareAttendanceData")
                && <Tab label={t('LEARNING_QUERY_RESULT_DATA')} {...TabsProps(3, 3 === tabValue)} />}
              {/* {{getPermissionValidity(PermissionCategory.SYSTEM, "Maintenance", "PrepareAttendanceData") &&
                                <Tab label={t('STUDENT_TOKEN_DATA')} {...TabsProps(2, 2 === tabValue)} />} */}
            </Tabs>

            <TabPanel value={tabValue} paddingValue={0} index={0}>
              <VMCard className="mb-8"
                titleClassName="my-4"
                titleComponent={
                  <div className={headingStyle}>
                    {t('SYNC_STATUS_LIST')}
                  </div>

                }
              >
                <div className="pb-4">
                  <div className="text-right mx-4">
                    <Button
                      className={`rounded-lg text-white bg-barPrimary text-sm mb-4`}
                      component="span"
                      onClick={() => {
                        setMultiSync(true);
                        setShowSyncDataModal(true);
                      }}
                    >
                      {t('SYNC_DATA')}
                    </Button>
                  </div>
                  {
                    maintenanceStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                      :
                      <table width="100%">
                        <thead className="bg-barPrimary text-white text-sm">
                          <td className="border-r p-2">ID</td>
                          {/*<td className="border-r p-2">{t('TABLE_NAME')}</td>*/}
                          <td className="border-r p-2">Simon {t('TABLE_NAME')}</td>
                          <td className="border-r p-2">{t('RECORDS')}</td>
                          <td className="border-r p-2">Simon {t('RECORDS')}</td>
                          <td className="border-r p-2 text-right">{t('SYNC_DATE_TIME')}</td>
                          <td className="border-r p-2 text-center">{t('ACTION')}</td>
                        </thead>
                        <tbody className="text-xs">
                          {
                            maintenanceStore.syncDataList.length > 0 &&
                            maintenanceStore.syncDataList.map((syncData, index) => (
                              <tr key={`sync_data_status_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                                <td className="py-2 pl-2">{syncData.id}</td>
                                {/*<td className="py-2 pl-2">{syncData.tableName}</td>*/}
                                <td>{syncData.simonTableName}</td>
                                <td className={`${syncData.recordCount != syncData.simonRecordCount && 'text-barPrimary font-bold underline'}`}>
                                  {syncData.recordCount}
                                </td>
                                <td className={`${syncData.recordCount != syncData.simonRecordCount && 'text-barPrimary font-bold underline'}`}>
                                  {syncData.simonRecordCount}
                                </td>
                                <td className="text-right pr-2">{syncData.syncDate.split("T")[0]} <span> </span>
                                  {syncData.syncDate.split("T")[1].substring(0, 8)}
                                </td>
                                <td className="text-center py-2">
                                  <button
                                    className="bg-buttonPrimary text-white text-xs uppercase p-1 rounded-lg"
                                    onClick={() => {
                                      setTableID(syncData.id);
                                      setMultiSync(false);
                                      setShowSyncDataModal(true);
                                    }}
                                  >
                                    {t('SYNC')}
                                  </button>
                                </td>
                              </tr>
                            ))

                          }
                        </tbody>
                      </table>
                  }
                </div>
              </VMCard>

              <CustomisedModal showModal={showSyncDataModal}>
                <div className="text-center m-4 mt-8">
                  <div
                    className="bg-white absolute top-1/3 left-1/2 p-8 border-t-8 border-barPrimary"
                    style={{ transform: 'translate(-50%)' }}
                  >
                    <div className="text-barPrimary w-full text-center text-xl mb-6">
                      {t('SYNC_DATA_CHECK')}?

                      <br /><HelpOutlineIcon fontSize='inherit' />
                      {multiSync ?
                        <span className="ml-2 text-sm">{t('SYNC_DATA_WARNING')}</span>
                        : <span className="ml-2 text-sm">{t('SYNC_DATA_CHECK')} —— {t('TABLE')} ID: {tableID}</span>
                      }
                    </div>
                    <Button
                      type="button"
                      className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                      onClick={() => setShowSyncDataModal(false)}
                    >
                      {t('CANCEL')}
                    </Button>
                    <Button
                      type="button"
                      className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                      onClick={() => {
                        if (multiSync) {
                          rootStore.notify(t('SYNC_DATA_WARNING'), 'warning');
                          maintenanceStore.onSyncData();
                        } else {
                          if (tableID != 0) {
                            maintenanceStore.syncDataByTableId(tableID);
                            rootStore.notify(t('SYNC_DATA_WARNING'), 'warning');
                          }
                        }
                        setShowSyncDataModal(false);
                      }}
                    >
                      {t('SYNC_NOW')}
                    </Button>
                  </div>
                </div>
              </CustomisedModal>
            </TabPanel>

            <TabPanel value={tabValue} paddingValue={0} index={1}>
              <SchoolAttendanceTab />
            </TabPanel>

            <TabPanel value={tabValue} paddingValue={0} index={2}>
              <StudentPerformanceTab />
            </TabPanel>

            <TabPanel value={tabValue} paddingValue={0} index={3}>
              <LearningQueryTab />
            </TabPanel>

            {/* <TabPanel value={tabValue} paddingValue={0} index={2}>
              <div className={componentStyle}>
                <div className={headingStyle}>
                  {t('CURRENT_STUDENT')}
                </div>
                <StudentTokenTab />
              </div>
            </TabPanel> */}

          </div>
        </Layout>
      }
    </>
  )
})

const SchoolAttendanceTab = observer(() => {
  const headingStyle = "text-barPrimary px-4 text-lg 2xl:text-2xl font-bold uppercase";
  const { t } = useTranslation();
  const componentStyle = "shadow-lg rounded-xl border mb-8";
  const { rootStore, attendanceStore, maintenanceStore, filterStore } = useStores();
  const [showPrepareArea, setShowPrepareArea] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState<any>();

  const onApplySemesterCheck = (year, semester) => {
    setYear(year);
    setSemester(filterStore.semesterList.filter(sem => sem.id == semester)[0] ? filterStore.semesterList.filter(sem => sem.id == semester)[0]
      : { id: 0, semesterDescription: "" });
    let result = maintenanceStore.schoolAttendanceList.filter(data => data.semesterId == semester);
    if (result.length > 0) setShowModal("update");
    else setShowModal("create");
  }

  const onPrepareAttendanceData = () => {
    console.log(semester.id);
    if (semester && semester.id != 0) {
      rootStore.notify(t('PREPARING_DATA_NOTIFY') + '!', 'warning');
      maintenanceStore.prepareSchoolAttendanceData(semester.id);
      setShowPrepareArea(false);
      setSemester({});
      setYear("");
      setShowModal("");
    } else if (semester.id == 0) {
      rootStore.notify(t('PREPARING_ALL_SEMESTER_DATA_NOTIFY') + '!', 'warning');
      maintenanceStore.prepareAllSemesterSchoolAttendanceData();
      setShowPrepareArea(false);
      setSemester({});
      setYear("");
      setShowModal("");
    }
  }

  return (
    <>
      <VMCard className="mb-8"
        titleClassName="my-4"
        titleComponent={
          <div className={headingStyle}>
            {t('SCHOOL_ATTENDANCE_LIST')}
          </div>
        }>
        <div className="px-8 pb-4">
          {attendanceStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            : <table width="100%">
              <thead className="bg-barPrimary text-center text-white">
                <td className="border-r py-1">{t('ACADEMIC_YEAR')}</td>
                <td className="border-r">{t('SEMESTER')}</td>
                <td className="border-r">{t('UPDATED_TIME')}</td>
              </thead>
              <tbody>
                {
                  maintenanceStore.schoolAttendanceList.length > 0 &&
                  maintenanceStore.schoolAttendanceList.map((a, index) => (
                    <tr key={`school_attendance_prepare_status_${index}`}
                      className={`text-center ${index % 2 != 0 && 'bg-gray-50'}`}
                    >
                      <td>{a.academicYear}</td>
                      <td>Semester {a.displaySemester}</td>
                      <td>{a.updateTime.split('T')[0] + " " + a.updateTime.split('T')[1].split('.')[0]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>}

          <div className="text-right">
            {!maintenanceStore.loading &&
              <>
                <Button
                  className="bg-buttonPrimary text-white mt-8"
                  onClick={() => setShowPrepareArea(true)}
                >
                  <AddCircleOutlineIcon /> <span className="ml-4">{t('PREPARE_ATTENDANCE_DATA')}</span>
                </Button>
              </>}
          </div>
        </div>
      </VMCard>

      {showPrepareArea &&
        <div className={`${componentStyle} mt-4 pt-4`}>
          <div className={headingStyle}>{t('PREPARE_NEW_ATTENDANCE_DATA')}</div>
          <div className="px-8 mb-2 text-lg text-barPrimary">
            <HelpOutlineIcon fontSize='inherit' />
            <span className="text-sm ml-2 text-barPrimary">
              {t('PLS_PICK_SEMESTER_FIRST')}
            </span>
          </div>
          <div className="px-8 pb-4">
            <PrepareStatusFilter callback={onApplySemesterCheck} />
          </div>
        </div>}

      <CustomisedModal showModal={showModal != ""}>
        {showModal != "" &&
          <div
            className="bg-white absolute top-1/3 left-1/2 p-8 border-t-8 border-barPrimary"
            style={{ transform: 'translate(-50%)' }}
          >
            <div className="text-barPrimary w-full text-center text-xl">
              {semester.id == 0 ? <div>{t('PREPARE_ALL_SEMESTER_ATTENDANCE_DATA_CHECK')}</div>
                : showModal == "update" ?
                  `${t('THE_SELECTED')} "${year + ' ' + semester.semesterDescription}"${t('ALREADY_EXISTS_IN_STATUS_LIST')}?`
                  :
                  `${t('PREPARE_ATTENDANCE_DATA_CHECK')}"${year + ' ' + semester.semesterDescription}"?`}
            </div>
            <div className="text-center m-4 mt-8">
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                onClick={() => setShowModal("")}
              >
                {t('CANCEL')}
              </Button>
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                onClick={onPrepareAttendanceData}
              >
                {showModal == "update" ? t('UPDATE') : t('CONTINUE')}
              </Button>
            </div>
          </div>}
      </CustomisedModal>
    </>
  )
});

const StudentPerformanceTab = observer(() => {
  const headingStyle = "text-barPrimary px-4 text-lg 2xl:text-2xl font-bold uppercase";
  const { t } = useTranslation();
  const componentStyle = "shadow-lg rounded-xl border mb-8";
  const { rootStore, attendanceStore, maintenanceStore, filterStore } = useStores();
  const [showPrepareArea, setShowPrepareArea] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState<any>();

  const onApplySemesterCheck = (year, semester) => {
    setYear(year);
    setSemester(filterStore.semesterList.filter(sem => sem.id == semester)[0] ? filterStore.semesterList.filter(sem => sem.id == semester)[0]
      : { id: 0, semesterDescription: "" });
    let result = maintenanceStore.studentPerformanceList.filter(data => data.semesterId == semester);
    if (result.length > 0) setShowModal("update");
    else setShowModal("create");
  }

  const onPrepareAttendanceData = () => {
    console.log(semester.id);
    if (semester && semester.id != 0) {
      rootStore.notify(t('PREPARING_DATA_NOTIFY') + '!', 'warning');
      maintenanceStore.prepareStudentOverallScoreAndAttendanceRateBySemesterId(semester.id);
      setShowPrepareArea(false);
      setSemester({});
      setYear("");
      setShowModal("");
    } else if (semester.id == 0) {
      rootStore.notify(t('PREPARING_ALL_SEMESTER_DATA_NOTIFY') + '!', 'warning');
      maintenanceStore.prepareAllSemesterStudentOverallScoreAndAttendanceRate();
      setShowPrepareArea(false);
      setSemester({});
      setYear("");
      setShowModal("");
    }
  }

  return (
    <>
      <VMCard className="mb-8"
        titleClassName="my-4"
        titleComponent={
          <div className={headingStyle}>
            {t('STUDENT_PERFORMANCE_LIST')}
          </div>
        }>
        <div className="px-8 pb-4">
          {attendanceStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            : <table width="100%">
              <thead className="bg-barPrimary text-center text-white">
                <td className="border-r py-1">{t('ACADEMIC_YEAR')}</td>
                <td className="border-r">{t('SEMESTER')}</td>
                <td className="border-r">{t('UPDATED_TIME')}</td>
              </thead>
              <tbody>
                {
                  maintenanceStore.studentPerformanceList.length > 0 &&
                  maintenanceStore.studentPerformanceList.map((a, index) => (
                    <tr key={`school_attendance_prepare_status_${index}`}
                      className={`text-center ${index % 2 != 0 && 'bg-gray-50'}`}
                    >
                      <td>{a.academicYear}</td>
                      <td>Semester {a.displaySemester}</td>
                      <td>{a.updateTime.split('T')[0] + " " + a.updateTime.split('T')[1].split('.')[0]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>}

          <div className="text-right">
            {!maintenanceStore.loading &&
              <>
                <Button
                  className="bg-buttonPrimary text-white mt-8"
                  onClick={() => setShowPrepareArea(true)}
                >
                  <AddCircleOutlineIcon /> <span className="ml-4">{t('PREPARE_STUDENT_PERFORMANCE_DATA')}</span>
                </Button>
              </>}
          </div>
        </div>
      </VMCard>

      {showPrepareArea &&
        <div className={`${componentStyle} mt-4 pt-4`}>
          <div className={headingStyle}>{t('PREPARE_STUDENT_PERFORMANCE_DATA')}</div>
          <div className="px-8 mb-2 text-lg text-barPrimary">
            <HelpOutlineIcon fontSize='inherit' />
            <span className="text-sm ml-2 text-barPrimary">
              {t('PLS_PICK_SEMESTER_FIRST')}
            </span>
          </div>
          <div className="px-8 pb-4">
            <PrepareStatusFilter callback={onApplySemesterCheck} />
          </div>
        </div>}

      <CustomisedModal showModal={showModal != ""}>
        {showModal != "" &&
          <div
            className="bg-white absolute top-1/3 left-1/2 p-8 border-t-8 border-barPrimary"
            style={{ transform: 'translate(-50%)' }}
          >
            <div className="text-barPrimary w-full text-center text-xl">
              {semester.id == 0 ? <div>{t('PREPARE_ALL_SEMESTER_STUDENT_PERFORMANCE_DATA_CHECK')}</div>
                : showModal == "update" ?
                  `${t('THE_SELECTED')} "${year + ' ' + semester.semesterDescription}"${t('ALREADY_EXISTS_IN_STATUS_LIST')}?`
                  :
                  `${t('PREPARE_STUDENT_PERFORMANCE_DATA_CHECK')}"${year + ' ' + semester.semesterDescription}"?`}
            </div>
            <div className="text-center m-4 mt-8">
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                onClick={() => setShowModal("")}
              >
                {t('CANCEL')}
              </Button>
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                onClick={onPrepareAttendanceData}
              >
                {showModal == "update" ? t('UPDATE') : t('CONTINUE')}
              </Button>
            </div>
          </div>}
      </CustomisedModal>
    </>
  )
});

const LearningQueryTab = observer(() => {
  const headingStyle = "text-barPrimary px-4 text-lg 2xl:text-2xl font-bold uppercase";
  const { t } = useTranslation();
  const componentStyle = "shadow-lg rounded-xl border mb-8";
  const { rootStore, attendanceStore, maintenanceStore, filterStore } = useStores();
  const [showPrepareArea, setShowPrepareArea] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [semester, setSemester] = useState<any>();

  const onApplySemesterCheck = (year, semester) => {
    setYear(year);
    setSemester(filterStore.semesterList.filter(sem => sem.id == semester)[0] ? filterStore.semesterList.filter(sem => sem.id == semester)[0]
      : { id: 0, semesterDescription: "" });
    let result = maintenanceStore.learningQueryList.filter(data => data.semesterId == semester);
    if (result.length > 0) setShowModal("update");
    else setShowModal("create");
  }

  const onPrepareAttendanceData = () => {
    console.log(semester.id);
    if (semester && semester.id != 0) {
      rootStore.notify(t('PREPARING_DATA_NOTIFY') + '!', 'warning');
      maintenanceStore.updateLearningQueryResultBySemesterId(semester.id);
      setShowPrepareArea(false);
      setSemester({});
      setYear("");
      setShowModal("");
    } else if (semester.id == 0) {
      rootStore.notify(t('PREPARING_ALL_SEMESTER_DATA_NOTIFY') + '!', 'warning');
      maintenanceStore.updateAllLearningQueryResult();
      setShowPrepareArea(false);
      setSemester({});
      setYear("");
      setShowModal("");
    }
  }

  return (
    <>
      <VMCard className="mb-8"
        titleClassName="my-4"
        titleComponent={
          <div className={headingStyle}>
            {t('LEARNING_QUERY_RESULT_LIST')}
          </div>
        }>
        <div className="px-8 pb-4">
          {attendanceStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            : <table width="100%">
              <thead className="bg-barPrimary text-center text-white">
                <td className="border-r py-1">{t('ACADEMIC_YEAR')}</td>
                <td className="border-r">{t('SEMESTER')}</td>
                <td className="border-r">{t('UPDATED_TIME')}</td>
              </thead>
              <tbody>
                {
                  maintenanceStore.learningQueryList.length > 0 &&
                  maintenanceStore.learningQueryList.map((a, index) => (
                    <tr key={`school_attendance_prepare_status_${index}`}
                      className={`text-center ${index % 2 != 0 && 'bg-gray-50'}`}
                    >
                      <td>{a.academicYear}</td>
                      <td>Semester {a.displaySemester}</td>
                      <td>{a.updateTime.split('T')[0] + " " + a.updateTime.split('T')[1].split('.')[0]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>}

          <div className="text-right">
            {!maintenanceStore.loading &&
              <>
                <Button
                  className="bg-buttonPrimary text-white mt-8"
                  onClick={() => setShowPrepareArea(true)}
                >
                  <AddCircleOutlineIcon /> <span className="ml-4">{t('UPDATE_LEARNING_QUERY_DATA')}</span>
                </Button>
              </>}
          </div>
        </div>
      </VMCard>

      {showPrepareArea &&
        <div className={`${componentStyle} mt-4 pt-4`}>
          <div className={headingStyle}>{t('UPDATE_LEARNING_QUERY_DATA')}</div>
          <div className="px-8 mb-2 text-lg text-barPrimary">
            <HelpOutlineIcon fontSize='inherit' />
            <span className="text-sm ml-2 text-barPrimary">
              {t('PLS_PICK_SEMESTER_FIRST')}
            </span>
          </div>
          <div className="px-8 pb-4">
            <PrepareStatusFilter callback={onApplySemesterCheck} />
          </div>
        </div>}

      <CustomisedModal showModal={showModal != ""}>
        {showModal != "" &&
          <div
            className="bg-white absolute top-1/3 left-1/2 p-8 border-t-8 border-barPrimary"
            style={{ transform: 'translate(-50%)' }}
          >
            <div className="text-barPrimary w-full text-center text-xl">
              {semester.id == 0 ? <div>{t('PREPARE_ALL_SEMESTER_LEARNING_QUERY_DATA_CHECK')}</div>
                : showModal == "update" ?
                  `${t('THE_SELECTED')} "${year + ' ' + semester.semesterDescription}"${t('ALREADY_EXISTS_IN_STATUS_LIST')}?`
                  :
                  `${t('PREPARE_LEARNING_QUERY_DATA_CHECK')}"${year + ' ' + semester.semesterDescription}"?`}
            </div>
            <div className="text-center m-4 mt-8">
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                onClick={() => setShowModal("")}
              >
                {t('CANCEL')}
              </Button>
              <Button
                type="button"
                className="bg-buttonPrimary text-white py-2 mx-2 text-sm 2xl:text-md"
                onClick={onPrepareAttendanceData}
              >
                {showModal == "update" ? t('UPDATE') : t('CONTINUE')}
              </Button>
            </div>
          </div>}
      </CustomisedModal>
    </>
  )
});


export default MaintenancePage;

